.form input {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contact h2 {
  font-family: 'DM Serif Display', serif;
}

.form-container {
  margin: 40px 0;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 90%;
}

.form-group {
  width: 100%;
  margin-bottom: 10px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.contact-btn {
  margin: 10px 0;
}

button.form-control.submit-success {
  background-color: #4CAF50;
  color: white;
}
