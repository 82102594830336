.footer {
  min-height: 40px;
  border-top: 1px solid #e0e0e0;
  background: #fff;
  position: relative;
  margin-top: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  padding: 3px;
  margin: 0;
}
