.App {
  text-align: center;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Arvo', serif;
}

.page-header {
  padding: 30px;
}

.projects-list-container {
  padding: 1.5rem;
}

.project-details {
  display: grid;
  /* grid with two columns and as many rows as needed */
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  /* gap between columns and rows */
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

/* every odd element */
.project-outer:nth-child(odd) {
  /* grid columns 1 and 2 */
  grid-column: 1 / 3;
}

/* every even element */
.project-outer:nth-child(even) {
  /* grid columns 2 and 3 */
  grid-column: 2 / 4;
}

/* Media query under 860px */
@media (max-width: 860px) {
  .project-details {
    /* grid with one column and as many rows as needed */
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .project-outer:nth-child(odd) {
    /* grid columns 1 and 2 */
    grid-column: 1 / 2;
  }

  /* every even element */
  .project-outer:nth-child(even) {
    /* grid columns 2 and 3 */
    grid-column: 1 / 2;
  }

  .project-outer .project {
    flex-direction: column;
  }
}

#bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  height: 100%;
}

#bio h1 {
  margin-bottom: 30px;
}

#bio h5, .page-header {
  font-family: 'DM Serif Display', serif;
  text-align: center;
}

#bio img {
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
  border: 3px solid #000;
  opacity: 0.6;
}

#bio h3 {
  padding: 20px 0;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.icons .icon {
  margin: 0 0.5rem;
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons .icon.jest, .icons .icon.js {
  font-size: 65px;
}

.home {
  height: 100%;
  align-items: center;
  justify-content: center;
}