nav.navbar {
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
}

nav ul.navbar-nav {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}